import { ValueOf } from 'type-fest';

export const Role = {
  Admin: 'admin',
  Viewer: 'viewer',
  User: 'user',
  Leasing: 'leasing',
  LeasingViewer: 'leasing-viewer',
};

export type TypeRole = ValueOf<typeof Role>;
